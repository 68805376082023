<script>
export default {
  created() {
    this.$sitio.auth.open({
      parent: this,
      reauth: true,
      action: this.$route.query.action || "login",
      props: this.$route.query,
    });
  },
};
</script>

<template>
  <div>...</div>
</template>
